<template>
  <div>
    <span :style="{ color: codeAbrvColor }">
      {{ changeMemberGrade }}
    </span>
  </div>
</template>

<script>
import GridCellTemplateMixin from "@/components/grid/GridCellTemplateMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";

export default {
  name: "ChangeMemberGradeCellTemplate",
  mixins: [GridCellTemplateMixin],
  computed: {
    codeAbrvColor() {
      return this.data.colorValue;
    },
    changeMemberGrade() {
      return commonCodesGetCommonCode("MEMBER_GRADE", false)?.find(
        ({ comCode }) => comCode === this.record.changeMemberGrade
      )?.comName;
    },
  },
};
</script>
