export default {
  props: {
    record: {
      type: Object,
    },
    field: {
      type: String,
    },
    rowIndex: {
      type: Number,
    },
    colIndex: {
      type: Number,
    },
    dataType: {
      type: String,
    },
    data: {},
    result: {
      type: String,
    },
  },
};
