<template>
  <div>
    (<span
      :style="{
        color: codeAbrvColor,
      }"
      >{{ data.codeAbrv }}</span
    >
    {{ changeOrder }})
  </div>
</template>

<script>
import GridCellTemplateMixin from "@/components/grid/GridCellTemplateMixin";

export default {
  name: "MemberGradeChangeCellTemplate",
  mixins: [GridCellTemplateMixin],
  computed: {
    codeAbrvColor() {
      return this.data.colorValue;
    },
    changeOrder() {
      return this.record.changeOrder;
    },
  },
};
</script>

<style scoped></style>
