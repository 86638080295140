<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.changeDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="searchMembersFilterOptions.changeDate"
                  type="lookup-condition"
                  @keydown.enter="onSearchClick"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li>
                    <ejs-dropdownbutton
                      ref="inputDateFromMenuDropdownButton"
                      cssClass="lookup-button-dropdown"
                      :items="inputDateFromMenuItems"
                      @select="inputDateFromMenuSelected"
                    >
                      {{ inputDateFromMenuItemsLabel || "날짜" }}
                    </ejs-dropdownbutton>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.memberGrades }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchMembersFilterOptions.memberGrades"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.memberGrades"
                  :fields="{ text: 'comName', value: 'comCode' }"
                  placeHolder="전체"
                  width="160"
                  @keydown.enter="onSearchClick"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.changeMemberGradeDivisions }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                  v-model="
                    searchMembersFilterOptions.changeMemberGradeDivisions
                  "
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.changeMemberGradeDivisions"
                  :fields="{ text: 'comName', value: 'comCode' }"
                  placeHolder="전체"
                  width="240"
                  @keydown.enter="onSearchClick"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.memberName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchMemberName"
                  v-model="searchMembersFilterOptions.memberName"
                  @focus="memberNoClear"
                  @change="onMemberNameChanged"
                  @keydown.enter="onSearchClick"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="openMemberPopup"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.isCouponPub }}
            </div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchMembersFilterOptions.isCouponPub"
                        :value="null"
                      />
                      <i></i>
                      <div class="label">전체</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchMembersFilterOptions.isCouponPub"
                        :value="true"
                      />
                      <i></i>
                      <div class="label">발행</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchMembersFilterOptions.isCouponPub"
                        :value="false"
                      />
                      <i></i>
                      <div class="label">미발행</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              :disabled="isLoading"
            @click.native="onSearchClick"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원등급 변경이력 목록</div>
              <div class="header-caption">
                [ {{ totalMembers | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="openMemberGradeChangeHistoryCouponPubPopup"
                  >
                    쿠폰발행 현황
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelClick"
                      :disabled="isMemberGradeChangesExcelDownloading"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <grid
              ref="memberGrid"
              id="memberGrid"
              v-bind="memberGridProps"
              @filtered="onMemberGridDataFiltered"
              @sorted="onMemberGridDataFiltered"
              @paged="onMemberGridDataFiltered"
            />
          </div>
        </section>
      </article>
    </div>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <member-grade-change-history-coupon-pub-popup
      v-if="isMemberGradeChangeHistoryCouponPubPopupOpen"
      ref="memberGradeChangeHistoryCouponPubPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberGradeChangeHistoryCouponPubPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.memberNameNumber .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import memberGradeChangeHistoryCouponPubPopup from "./popup/MemberGradeChangeHistoryCouponPubPopup";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import Grid from "@/components/grid/Grid";
import MemberGradeChangeCellTemplate from "@/views/member-management/template/MemberGradeChangeCellTemplate";
import ChangeMemberGradeCellTemplate from "@/views/member-management/template/ChangeMemberGradeCellTemplate";
import {
  FORMAT_MEMBER_NUMBER,
  FORMAT_NUMBER,
  FORMAT_TEL_CELLPHONE_NUMBER,
} from "@/components/grid/GridCellDataFormatUtil";

export default {
  name: "memberGradeChangeHistory",
  components: {
    Grid,
    InputText,
    InputDateRange,
    memberSelectPopup,
    memberGradeChangeHistoryCouponPubPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  created() {
    this.searchMembersFilterOptions.changeDate.from = getFormattedDate(
      new Date()
    );
    this.searchMembersFilterOptions.changeDate.to = getFormattedDate(
      new Date()
    );
    this.searchOptions.memberGrades = commonCodesGetCommonCode("MEMBER_GRADE");
    this.searchOptions.changeMemberGradeDivisions = commonCodesGetCommonCode(
      "MEMBER_GRADE_CHANGE"
    );
  },
  mounted() {
    this.$refs.searchMemberName.focusIn();
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,

      isMemberSelectPopupOpen: false,
      isMemberGradeChangeHistoryCouponPubPopupOpen: false,

      inputDateFromMenuItemsLabel: null,

      searchMembersFilterOptions: {
        changeDate: { to: null, from: null },
        memberGrades: null,
        changeMemberGradeDivisions: null,
        memberName: null,
        membershipId: null,
        isCouponPub: null,
      },
      tempSavedsearchMembersFilterOptions: {},

      searchOptions: {
        memberGrades: null,
        changeMemberGradeDivisions: null,
      },

      labels: {
        changeDate: "변경일자",
        memberGrades: "회원등급",
        changeMemberGradeDivisions: "변경구분",
        memberName: "회원검색",
        isCouponPub: "쿠폰",
      },

      totalMembers: 0,
      members: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapGetters("documents", ["isMemberGradeChangesExcelDownloading"]),
    commonCodesGetCommonCode,
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isMemberGradeChangeHistoryCouponPubPopupOpen
      );
    },
    datetimeString() {
      return moment().format(`YYYY-MM-DD HH:mm:ss`);
    },
    memberGridProps() {
      return {
        columns: [
          {
            minWidth: 16,
            name: "변경일자",
            width: 90,
            field: "changeDate",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            name: "회원정보",
            columns: [
              {
                minWidth: 16,
                name: "회원명",
                width: 90,
                field: "memberName",
                type: String,
              },
              {
                minWidth: 16,
                name: "회원번호",
                width: 90,
                field: "membershipMemberNumber",
                type: String,
                format: FORMAT_MEMBER_NUMBER,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "연락처",
                width: 100,
                sortable: false,
                filterable: false,
                field: "memberPhoneNumber",
                type: String,
                format: FORMAT_TEL_CELLPHONE_NUMBER,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "웹 ID",
                width: 120,
                field: "memberWebIdInformationId",
                type: String,
                format: (memberWebIdInformationId) => {
                  if (["KAKAO", "NAVER"].includes(memberWebIdInformationId?.split(":")?.[0])) {
                    return memberWebIdInformationId?.split(":")?.[0];
                  } else {
                    return memberWebIdInformationId;
                  }
                },
              },
            ],
          },
          {
            name: "기준일자",
            columns: [
              {
                minWidth: 16,
                name: "시작",
                width: 90,
                field: "stdStartDate",
                type: Date,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "종료",
                width: 90,
                field: "stdEndDate",
                type: Date,
                cellStyle: {
                  textAlign: "center",
                },
              },
            ],
          },
          {
            name: "회원등급",
            columns: [
              {
                minWidth: 16,
                name: "변경 전",
                width: 120,
                field: "beforeMemberGradeComName",
                type: String,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "변경 후",
                width: 120,
                field: "changeMemberGradeComName",
                type: String,
                iteratee: ({ memberGradeChange }) =>
                  commonCodesGetCommonCode("MEMBER_GRADE_CHANGE", false)?.find(
                    ({ comCode }) => comCode === memberGradeChange
                  ),
                cellTemplate: ChangeMemberGradeCellTemplate,
                cellStyle: {
                  textAlign: "center",
                },
              },
            ],
          },
          {
            minWidth: 16,
            name: "변경구분",
            width: 90,
            field: "memberGradeChangeCodeAbrv",
            sortable: false,
            filterable: false,
            type: String,
            iteratee: ({ memberGradeChange }) =>
              commonCodesGetCommonCode("MEMBER_GRADE_CHANGE", false)?.find(
                ({ comCode }) => comCode === memberGradeChange
              ),
            cellTemplate: MemberGradeChangeCellTemplate,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            name: "쿠폰",
            columns: [
              {
                minWidth: 16,
                name: "쿠폰종류",
                width: 90,
                field: "couponName",
                type: String,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "발행여부",
                width: 90,
                field: "isCouponPub",
                type: Boolean,
                cellStyle: {
                  textAlign: "center",
                },
              },
            ],
          },
          {
            name: "회원실적",
            columns: [
              {
                minWidth: 16,
                name: "내장횟수",
                width: 90,
                field: "visitCnt",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "내장점수",
                width: 90,
                field: "visitScore",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "입장료",
                width: 90,
                field: "feeSales",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "식음매출",
                width: 90,
                field: "foodSales",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "프로샵",
                width: 90,
                field: "shopSales",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
            ],
          },
          {
            minWidth: 16,
            name: "확정자",
            width: 90,
            field: "userName",
            type: String,
          },
          {
            minWidth: 16,
            name: "확정일시",
            width: 140,
            field: "insertDt",
            type: "datetime",
            cellStyle: {
              textAlign: "center",
            },
          },
        ],
        records: this.members,
        visibleRowNumberColumn: true,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.totalMembers,
        rangeOfPages: 10,
      };
    },
    inputDateFromMenuItems() {
      return [
        {
          id: 1,
          text: "오늘",
        },
        {
          id: 2,
          text: "3개월",
        },
        {
          id: 3,
          text: "6개월",
        },
        {
          id: 4,
          text: "1년",
        },
      ];
    },
  },
  methods: {
    ...mapActions("documents", ["downloadExcelMemberGradeChanges"]),
    onMemberGridDataFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.searchMembers({
        offset: (page - 1) * 50,
        filters,
        orders,
      });
    },
    openMemberPopup() {
      this.isMemberSelectPopupOpen = true;

      let memberData = {
        memberNameNo: this.searchMembersFilterOptions.memberName,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      if (data.selectedRowData) {
        if (!data.selectedRowData.membershipId) {
          this.searchMembersFilterOptions.memberName = null;
          this.searchMembersFilterOptions.membershipId = null;
        } else {
          this.searchMembersFilterOptions.memberName =
            data.selectedRowData.memberName;
          this.searchMembersFilterOptions.membershipId =
            data.selectedRowData.membershipId;
        }
      }
    },
    memberNoClear() {
      this.searchMembersFilterOptions.memberName = null;
    },
    onMemberNameChanged() {
      this.searchMembersFilterOptions.membershipId = null;
    },
    inputDateFromMenuSelected(args) {
      const inputDateFrom = moment(
        this.searchMembersFilterOptions.changeDate.to
      );
      const amountList = [0, 3, 6, 12];
      this.searchMembersFilterOptions.changeDate.from = inputDateFrom
        .subtract(amountList[args.item.id - 1], "months")
        .format("YYYY-MM-DD");
      this.inputDateFromMenuItemsLabel = this.inputDateFromMenuItems.find(
        (item) => item.id === args.item.id
      )?.text;
    },
    async onSearchClick() {
      this.onMemberNameChanged();

      this.$refs["memberGrid"].resetPage();
      await this.searchMembers();

      if (this.$refs["memberGrid"].getSelectedRows().length < 1) {
        this.$refs["memberGrid"].selectRow(0);
      }
    },
    async openMemberGradeChangeHistoryCouponPubPopup() {
      if (this.isMemberGradeChangeHistoryCouponPubPopupOpen) {
        return;
      }

      if (this.isLoading) {
        return this.errorToast("데이터를 불러오는 중입니다.");
      }

      if (
        !this.searchMembersFilterOptions.changeDate.from ||
        !this.searchMembersFilterOptions.changeDate.to
      ) {
        return this.errorToast("기준일자를 입력해 주시기 바랍니다.");
      }

      this.isLoading = true;

      const args = this.tempSavedsearchMembersFilterOptions;
      delete args.isCouponPub;
      delete args.limit;
      delete args.offset;
      delete args.orders;

      try {
        const data = await GolfErpAPI.getMemberGradeChangeHistoryCouponPub(
          args
        );
        if (data.total < 1) {
          return this.errorToast("데이터가 없습니다");
        }

        this.isMemberGradeChangeHistoryCouponPubPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.memberGradeChangeHistoryCouponPubPopup.showPopup(data);
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onExcelClick() {
      if (
        !this.searchMembersFilterOptions.changeDate.from ||
        !this.searchMembersFilterOptions.changeDate.to
      ) {
        return this.errorToast("변경일자를 입력해 주시기 바랍니다.");
      }
      if (50000 < this.totalMembers) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }

      const { orders: gridOrders, filters: gridFilters } = this.$refs[
        "memberGrid"
      ].getOrdersAndFiltersAndPage();
      const columns = this.$refs["memberGrid"].getColumns();

      const _orders = JSON.parse(JSON.stringify(gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "changeDate") < 0) {
        _orders.push({
          field: "changeDate",
          direction: "desc",
        });
      }
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      this.downloadExcelMemberGradeChanges({
        payload: {
          changeDateFrom: this.searchMembersFilterOptions.changeDate.from,
          changeDateTo: this.searchMembersFilterOptions.changeDate.to,
          memberGrades:
            this.searchMembersFilterOptions.memberGrades === null
              ? undefined
              : this.searchMembersFilterOptions.memberGrades,
          changeMemberGradeDivisions:
            this.searchMembersFilterOptions.changeMemberGradeDivisions === null
              ? undefined
              : this.searchMembersFilterOptions.changeMemberGradeDivisions,
          memberName:
            !this.searchMembersFilterOptions.memberName ||
            this.searchMembersFilterOptions.memberName === ""
              ? undefined
              : this.searchMembersFilterOptions.memberName,
          membershipId: !this.searchMembersFilterOptions.membershipId
            ? undefined
            : this.searchMembersFilterOptions.membershipId,
          isCouponPub:
            this.searchMembersFilterOptions.isCouponPub === null
              ? undefined
              : this.searchMembersFilterOptions.isCouponPub,
          filters: gridFilters,
          orders: _orders,
          columns,
        },
      });
    },
    memberGradeChangeHistoryCouponPubPopupClosed() {
      this.isMemberGradeChangeHistoryCouponPubPopupOpen = false;
    },
    async searchMembers({ offset, filters, orders } = {}) {
      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["memberGrid"].getOrdersAndFiltersAndPage();

      const limit = this.memberGridProps.sizeOfRecordPerPage;

      if (this.isLoading) {
        return this.errorToast("데이터를 불러오는 중입니다.");
      }

      if (
        !this.searchMembersFilterOptions.changeDate.from ||
        !this.searchMembersFilterOptions.changeDate.to
      ) {
        return this.errorToast("변경일자를 입력해 주시기 바랍니다.");
      }

      this.isLoading = true;

      const args = {
        changeDateFrom: this.searchMembersFilterOptions.changeDate.from,
        changeDateTo: this.searchMembersFilterOptions.changeDate.to,
        memberGrades:
          this.searchMembersFilterOptions.memberGrades === null
            ? undefined
            : this.searchMembersFilterOptions.memberGrades,
        changeMemberGradeDivisions:
          this.searchMembersFilterOptions.changeMemberGradeDivisions === null
            ? undefined
            : this.searchMembersFilterOptions.changeMemberGradeDivisions,
        memberName:
          !this.searchMembersFilterOptions.memberName ||
          this.searchMembersFilterOptions.memberName === ""
            ? undefined
            : this.searchMembersFilterOptions.memberName,
        membershipId: !this.searchMembersFilterOptions.membershipId
          ? undefined
          : this.searchMembersFilterOptions.membershipId,
        isCouponPub:
          this.searchMembersFilterOptions.isCouponPub === null
            ? undefined
            : this.searchMembersFilterOptions.isCouponPub,
        limit,
        offset: offset || (gridPage - 1) * limit,
        filters: filters || gridFilters,
        orders: orders || gridOrders,
      };
      this.tempSavedsearchMembersFilterOptions = args;

      try {
        const {
          total: totalMembers,
          records,
        } = await GolfErpAPI.fetchRecordsAndCountMemberGradeChanges(args);

        this.totalMembers = totalMembers;
        this.members = records;
        this.isSearched = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
