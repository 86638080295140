<template>
  <div>
    <ejs-dialog
      ref="memberGradeChangeHistoryCouponPubPopup"
      header="회원등급 변경이력-쿠폰발행 현황"
      :allowDragging="true"
      :showCloseIcon="true"
      width="400"
      height="560"
      :isModal="true"
      @close="onMemberGradeChangeHistoryCouponPubPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="membershipFitPaymtGrid"
                        :selectionSettings="selectionSettings"
                        :dataSource="memberGradeChangeHistoryCouponPubList"
                        :columns="memberGradeChangeHistoryCouponPubGridColumn"
                        :aggregates="
                          memberGradeChangeHistoryCouponPubGridAggregates
                        "
                        :provides="grid"
                        :allowResizing="true"
                        :isInPopup="true"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="closeMemberGradeChangeHistoryCouponPubPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-body {border: 0;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  Edit,
  Aggregate,
  Filter,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MemberGradeChangeHistoryCouponPubPopup",
  components: { EjsGridWrapper, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ForeignKey],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      getCouponPubCommonParameter: null,
      memberGradeChangeHistoryCouponPubList: [],
      memberGradeChangeHistoryCouponPubGridColumn: [
        {
          field: "couponName",
          headerText: "쿠폰종류",
          type: "string",
          isPrimaryKey: true,
        },
        {
          field: "couponCount",
          headerText: "발행건수",
          textAlign: "right",
          type: "string",
          width: 120,
          isNumericType: true,
        },
      ],
      memberGradeChangeHistoryCouponPubGridAggregates: [
        {
          columns: [
            {
              field: "couponName",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "couponCount",
              aggregationType: "TotalSum",
              stringFormat: "${value} 건",
              textColor: "red",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async showPopup(popupData) {
      this.memberGradeChangeHistoryCouponPubList = popupData.coupons;
    },
    onMemberGradeChangeHistoryCouponPubPopupClosed() {
      this.$emit("popupClosed");
    },
    closeMemberGradeChangeHistoryCouponPubPopup() {
      this.$refs.memberGradeChangeHistoryCouponPubPopup.hide();
    },
  },
};
</script>
